exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-front-page-js": () => import("./../../../src/templates/frontPage.js" /* webpackChunkName: "component---src-templates-front-page-js" */),
  "component---src-templates-lookbook-js": () => import("./../../../src/templates/lookbook.js" /* webpackChunkName: "component---src-templates-lookbook-js" */),
  "component---src-templates-stockists-js": () => import("./../../../src/templates/stockists.js" /* webpackChunkName: "component---src-templates-stockists-js" */),
  "component---src-templates-terms-js": () => import("./../../../src/templates/terms.js" /* webpackChunkName: "component---src-templates-terms-js" */),
  "component---src-templates-types-collection-js": () => import("./../../../src/templates/types/collection.js" /* webpackChunkName: "component---src-templates-types-collection-js" */),
  "component---src-templates-types-page-js": () => import("./../../../src/templates/types/page.js" /* webpackChunkName: "component---src-templates-types-page-js" */),
  "component---src-templates-vision-js": () => import("./../../../src/templates/vision.js" /* webpackChunkName: "component---src-templates-vision-js" */)
}

